<template>
  <div class="static-chart">
    <vue-apex-charts ref="chart" type="area" height="220" :options="chartOptions" :series="series" :key="chartKey" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import config from './chartConfig.js'
import _color from '@/assets/utils/color.js'

export default {
  name: 'StaticChart',
  data() {
    return {
      chartOptions: null,
      chartKey: Math.random().toString(36).substring(2, 15)
    }
  },
  components: {
    VueApexCharts
  },
  props: {
    series: {
      type: Array
    },
    xaxis: {
      type: Object
    },
    title: {
      type: String
    },
    color: {
      type: String
    }
  },
  watch: {
    series: {
      deep: true,
      handler() {
        this.$refs.chart.updateSeries([
          {
            data: this.series[0].data
          }
        ])
      }
    },
    // Update Title when locale changes
    '$i18n.locale'() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          title: {
            text: this.title
          }
        }
      }

      this.chartKey = Math.random().toString(36).substring(2, 15)
    }
  },
  created() {
    this.chartOptions = Object.assign({}, config.chartOptions)

    this.chartOptions = {
      ...this.chartOptions,
      ...{
        title: {
          text: this.title
        },
        xaxis: {
          categories: this.xaxis.categories
        },
        colors: [this.color],
        theme: {
          monochrome: {
            enabled: true,
            color: this.getHex(this.color),
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        }
      }
    }
  },

  methods: {
    getHex(color) {
      if (_color.isColor(color)) {
        let rgb = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${color}`)
        rgb = rgb.split(',')
        return `#${((1 << 24) + (Number(rgb[0]) << 16) + (Number(rgb[1]) << 8) + Number(rgb[2])).toString(16).slice(1)}`
      }
      return color
    }
  }
}
</script>

<style lang="scss">
.static-chart {
  .apexcharts-title-text {
    font-family: 'Lato', Helvetica, Arial, sans-serif !important;
  }

  .apexcharts-xaxis-label {
    font-family: 'Lato', Helvetica, Arial, sans-serif !important;
    font-size: 1em;
  }

  .apexcharts-xaxis {
    display: block !important;
  }
}
</style>
