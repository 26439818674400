<template>
  <div class="account-filter">
    <multiselect
      v-model="selectedOption"
      :options="accountList"
      @input="updateSelectedOption"
      :multiple="false"
      :searchable="true"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="$t('vue.selectAccount')"
      :selectLabel="$t('vue.pressEnterToSelect')"
      :selectedLabel="$t('vue.selectedLabel')"
      :deselectLabel="$t('vue.pressEnterToRemove')"
      label="name"
      track-by="name"
      :preselect-first="false"
      :class="isEmpty ? 'account-filter--empty' : ''"
    >
    </multiselect>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AccountsFilter',
  data: () => ({
    selectedOption: null
  }),
  components: {
    Multiselect
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      accountList: 'accountList',
      selectedAccount: 'selectedAccount'
    }),
    isEmpty() {
      return this.selectedOption === null
    }
  },

  async created() {
    this.selectedOption = this.selectedAccount
  },
  methods: {
    ...mapMutations({
      setSelectedAccount: 'SET_SELECTED_ACCOUNT'
    }),
    updateSelectedOption() {
      let selectedOption = this.selectedOption
      if (!selectedOption) {
        selectedOption = {
          id: this.activeUserInfo.company,
          name: this.company.name,
          vegaActivationDate: this.company.vegaActivationDate
        }
      }
      this.setSelectedAccount(selectedOption)
      this.selectedOption = selectedOption
      this.$emit('accountChanged', this.selectedOption)
    }
  },
  watch: {
    selectedAccount() {
      this.selectedOption = this.selectedAccount
    }
  }
}
</script>

<style lang="scss">
.account-filter {
  width: 300px;
  height: 45px;
  max-height: 40px;

  @media only screen and (max-width: 1440px) {
    width: 250px;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  &--empty {
    .multiselect__input {
      padding-left: 40px;
    }
  }

  .multiselect {
    color: #000;
    z-index: 1002;

    &__input {
      padding-left: 40px;
    }

    &__select {
      z-index: 1;

      &::before {
        border-color: #000 transparent transparent;
      }
    }

    &__placeholder {
      padding-top: 0;
      margin-bottom: 0px;
    }

    &__tags {
      background: #e6ecf1;
      padding: 10px 5px 0 5px;
      text-align: center;

      &-wrap {
        padding-left: 40px;
      }
    }

    &__single {
      background: #e6ecf1;
      font-size: 1em;
    }

    &__input {
      background: #e6ecf1;
    }

    &__content-wrapper {
      z-index: 1003;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 25px 0px;
      width: fit-content;
    }

    &--active {
      &__tags {
        padding: 10px 40px 0 8px;
      }
    }
  }

  &-select {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 400;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0.01rem;
  }
}
</style>
