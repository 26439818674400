<template>
  <div class="campaign-filter">
    <multiselect
      v-model="selectedOptions"
      :options="campaignList"
      @input="updateSelectedCampaign"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="$t('vue.selectCampaign')"
      :selectLabel="$t('vue.pressEnterToSelect')"
      :selectedLabel="$t('vue.selectedLabel')"
      :deselectLabel="$t('vue.pressEnterToRemove')"
      label="name"
      track-by="name"
      :preselect-first="false"
      :class="isEmpty ? 'campaign-filter--empty' : ''"
    >
      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single" v-if="values.length && !isOpen"
          >{{ values.length }} {{ values.length > 1 ? $t('vue.campaignsSelected') : $t('vue.campaignSelected') }}</span
        >
      </template>
    </multiselect>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CampaignFilter',
  data: () => ({
    selectedOptions: null
  }),
  components: {
    Multiselect
  },
  props: {
    campaignList: {
      type: Array,
      required: true
    },
    selectedCampaigns: {
      type: Array,
      required: true
    }
  },
  computed: {
    isEmpty() {
      return !this.selectedOptions.length
    }
  },
  watch: {
    selectedCampaigns(value) {
      this.selectedOptions = value
    }
  },
  created() {
    this.selectedOptions = this.selectedCampaigns
  },
  methods: {
    updateSelectedCampaign() {
      this.$emit('campaignChanged', this.selectedOptions)
    }
  }
}
</script>

<style lang="scss">
.campaign-filter {
  width: 300px;
  height: 45px;
  max-height: 40px;

  @media only screen and (max-width: 1440px) {
    width: 250px;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  &--empty {
    .multiselect__input {
      padding-left: 40px;
    }
  }

  .multiselect {
    color: #000;
    z-index: 1000;

    &__select {
      z-index: 1;

      &::before {
        border-color: #000 transparent transparent;
      }
    }

    &__placeholder {
      padding-top: 0;
      margin-bottom: 0px;
    }

    &__tags {
      background: #e6ecf1;
      padding: 10px 5px 0 5px;
      text-align: center;

      &-wrap {
        padding-left: 40px;
      }
    }

    &__single {
      background: #e6ecf1;
      font-size: 1em;
    }

    &__input {
      background: #e6ecf1;
    }

    &__content-wrapper {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 25px 0px;
    }

    &--active {
      &__tags {
        padding: 10px 40px 0 8px;
      }
    }
  }

  &-select {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 400;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0.01rem;
  }
}
</style>
