var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info summary-info w-full relative" },
    [
      !_vm.isLoading
        ? _c("vx-card", { staticClass: "summary-info__card" }, [
            _c("div", { staticClass: "summary-info__data" }, [
              _c("ul", { staticClass: "summary-info__data__list" }, [
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: _vm.$t(
                            "views.home.totalNumberOfVegaVisitingWithinTheSelectedDateRange"
                          ),
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[2])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatterDot")(
                              _vm.sumTotalVegaVisitorsCountPerDay,
                              false
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: _vm.$t(
                            "views.home.numberOfVisitorsWhoShowedStrongBuyingIntent"
                          ),
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[3])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatterDot")(
                              _vm.sumThresholdVegaVisitorsCountPerDay,
                              false
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "summary-info__data__list__item" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: {
                          width: "17px",
                          margin: "5px 10px 0px 0px",
                        },
                        attrs: {
                          text: _vm.$t(
                            "views.home.shareOfVisitorsWhoShowedStrongBuyingIntent"
                          ),
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__label" },
                      [_vm._v(_vm._s(_vm.$t(_vm.demoSummaryDataLabels[4])))]
                    ),
                    _c(
                      "div",
                      { staticClass: "summary-info__data__list__item__value" },
                      [_vm._v(_vm._s(_vm.percentVegaVisitorsCountPerDay))]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "summary-info__data__chart" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { "vs-trigger-click": "" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "a-icon summary-info__data__chart__view-label",
                              attrs: { href: "#" },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm
                                      .displayedActiveChartViewRangeOnAnalyticsSummary
                                      .text
                                  ) +
                                  "\n\n              "
                              ),
                              _c("vs-icon", { attrs: { icon: "expand_more" } }),
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            _vm._l(_vm.chartViewRangeList, function (item) {
                              return _c(
                                "vs-dropdown-item",
                                {
                                  key: item.key,
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveChartViewRange(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.text) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("static-chart-spline", {
                    attrs: {
                      series: _vm.totalVegaVisitorsCountPerDay,
                      xaxis: _vm.xaxisValues,
                      color: _vm.color,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("ul", { staticClass: "summary-info__footer-data-list" }, [
              _c(
                "li",
                { staticClass: "summary-info__footer-data-list__item" },
                [
                  _c("div", {
                    staticClass: "summary-info__footer-data-list__item__label",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("views.home.yourAverageSale")),
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "summary-info__footer-data-list__item__value summary-info__footer-data-list__item__value--sales",
                    },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|numeric|min_value:0",
                            expression: "'required|numeric|min_value:0'",
                          },
                        ],
                        staticClass:
                          "w-full summary-info__footer-data-list__item__input",
                        attrs: { name: "averageSale", type: "number" },
                        model: {
                          value: _vm.averageSale,
                          callback: function ($$v) {
                            _vm.averageSale = $$v
                          },
                          expression: "averageSale",
                        },
                      }),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("€")]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "li",
                { staticClass: "summary-info__footer-data-list__item" },
                [
                  _c("div", {
                    staticClass:
                      "summary-info__footer-data-list__item__label summary-info__footer-data-list__item__label--margin",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("views.home.salesPotential")),
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "summary-info__footer-data-list__item__value summary-info__footer-data-list__item__value--sales",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("formatterDot")(_vm.salesPotential)) +
                          " "
                      ),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("€")]),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }