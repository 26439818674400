<template>
  <div class="chart-info relative">
    <vx-card v-if="isLoaded">
      <div class="flex flex-row items-center">
        <div class="relative w-full">
          <vs-list-header :title="$t(title)" class="card-title-font-size" color="primary"></vs-list-header>
        </div>
      </div>
      <!-- CHART -->
      <div>
        <static-chart :series="series" :xaxis="xaxisValues" :color="color" />
      </div>
    </vx-card>
    <placeholder-text-small v-else />
  </div>
</template>

<script>
import StaticChart from '@/components/static-chart/StaticChart.vue'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  name: 'RequestsPerWeek',
  components: {
    StaticChart,
    PlaceholderTextSmall
  },
  props: {
    isLoaded: {
      type: Boolean
    },
    series: {
      type: Array
    },
    weekdays: {
      type: Array
    }
  },
  data: () => ({
    color: '#f1a342'
  }),
  computed: {
    xaxisValues() {
      return {
        categories: this.weekdays
      }
    },
    title() {
      return this.$i18n.t('views.home.customerReqeuestsCountPerDay')
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba($color: #000000, $alpha: 0.1);
}
</style>
