<template>
  <div class="filters">
    <div v-if="activeUserInfo.superadmin" class="filters-container">
      <feather-icon icon="FilterIcon" class="filters-container-icon-filter" />
      <accounts-filter @accountChanged="handleAccountChange" />
    </div>
    <div v-if="isCampaignFilter" class="filters-container">
      <feather-icon icon="FilterIcon" class="filters-container-icon-filter" />
      <campaign-filter :campaignList="campaignList" :selectedCampaigns="selectedCampaigns" @campaignChanged="handleCampaignChange" />
    </div>
    <div class="filters-container">
      <feather-icon icon="FilterIcon" class="filters-container-icon-filter" />
      <dialog-filter :dialogList="dialogList" :selectedDialogs="selectedDialogs" @dialogChanged="handleDialogChange" />
    </div>
    <div class="filters-container">
      <feather-icon icon="CalendarIcon" class="filters-container-icon-date_range" />
      <date-range-filter :localeData="localeData" @dateRangeChanged="handleDateRangeChange" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DateRangeFilter from '@/views/home/components/DateRangeFilter.vue'
import DialogFilter from '@/views/home/components/DialogFilter.vue'
import CampaignFilter from '@/views/home/components/CampaignFilter.vue'
import AccountsFilter from '@/views/home/components/AccountsFilter.vue'

export default {
  name: 'Filters',
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    })
  },
  components: {
    DateRangeFilter,
    DialogFilter,
    CampaignFilter,
    AccountsFilter
  },
  props: {
    dialogList: {
      type: Array,
      required: true
    },
    campaignList: {
      type: Array,
      required: true
    },
    selectedDialogs: {
      type: Array,
      required: true
    },
    selectedCampaigns: {
      type: Array,
      required: true
    },
    localeData: {
      type: Object,
      required: true
    },
    isCampaignFilter: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    handleCampaignChange(value) {
      this.$emit('campaignChanged', value)
    },
    handleDialogChange(value) {
      this.$emit('dialogChanged', value)
    },
    handleDateRangeChange(value) {
      this.$emit('dateRangeChanged', value)
    },
    handleAccountChange(value) {
      this.$emit('accountChanged', value)
    }
  }
}
</script>

<style lang="scss">
.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
  }

  &-container {
    display: flex;
    align-items: center;
    position: relative;

    &-icon {
      &-filter {
        position: absolute !important;
        z-index: 1001;
        top: 8px;
        left: 9px;
      }

      &-date_range {
        position: absolute !important;
        z-index: 1;
        top: 8px;
        left: 10px;
      }
    }
  }

  @media only screen and (max-width: 481px) {
    flex-direction: column;

    &-container {
      margin-bottom: 15px;
      width: 100%;
    }
  }
}
</style>
