import { mapGetters } from 'vuex'

export const analyticsData = {
  computed: {
    ...mapGetters({
      company: 'company',
      sessionsData: 'dashboard/sessionsData',
      totalVisitors: 'dashboard/totalVisitors',
      uniqueVisitors: 'dashboard/uniqueVisitors',
      returningVisitors: 'dashboard/returningVisitors',
      requestCount: 'dashboard/requestCount',
      chatRequestCount: 'dashboard/chatRequestCount',
      audioRequestCount: 'dashboard/audioRequestCount',
      videoRequestCount: 'dashboard/videoRequestCount',
      vegaRequestCount: 'dashboard/vegaRequestCount',
      vegaChatRequestCount: 'dashboard/vegaChatRequestCount',
      vegaAudioRequestCount: 'dashboard/vegaAudioRequestCount',
      vegaVideoRequestCount: 'dashboard/vegaVideoRequestCount',

      sessionsCount: 'dashboard/sessionsCount',
      vegaSessionsCount: 'dashboard/vegaSessionsCount',
      chatSessionCount: 'dashboard/chatSessionCount',
      audioSessionCount: 'dashboard/audioSessionCount',
      videoSessionCount: 'dashboard/videoSessionCount',

      vegaChatSessionCount: 'dashboard/vegaChatSessionCount',
      vegaAudioSessionCount: 'dashboard/vegaAudioSessionCount',
      vegaVideoSessionCount: 'dashboard/vegaVideoSessionCount',

      /* CAMPAIGNS */
      campaignRequestCount: 'dashboard/campaignRequestCount',
      campaignChatRequestCount: 'dashboard/campaignChatRequestCount',
      campaignAudioRequestCount: 'dashboard/campaignAudioRequestCount',
      campaignVideoRequestCount: 'dashboard/campaignVideoRequestCount',
      campaignSessionsCount: 'dashboard/campaignSessionsCount',
      campaignChatSessionCount: 'dashboard/campaignChatSessionCount',
      campaignAudioSessionCount: 'dashboard/campaignAudioSessionCount',
      campaignVideoSessionCount: 'dashboard/campaignVideoSessionCount',

      activeChartViewRangeOnAnalyticsSummary: 'dashboard/activeChartViewRangeOnAnalyticsSummary',

      vegaVisitorsCountPerDay: 'dashboard/vegaVisitorsCountPerDay',
      vegaAgentConversationRating: 'dashboard/vegaAgentConversationRating',
      vegaConversationStatus: 'dashboard/vegaConversationStatus',
      vegaVisitorAnalytics: 'dashboard/vegaVisitorAnalytics',
      vegaFilledContactFormStatus: 'dashboard/vegaFilledContactFormStatus',
      vegaScoresAnalytics: 'dashboard/vegaScoresAnalytics',
      vegaCampaigns: 'dashboard/vegaCampaigns',
      vegaVisitorScoreFlow: 'dashboard/vegaVisitorScoreFlow',
      selectedVegaUserFlow: 'dashboard/selectedVegaUserFlow'
    }),
    uniqueVisitorsPercent() {
      if (this.uniqueVisitors && this.totalVisitors) {
        return parseInt((this.uniqueVisitors * 100) / this.totalVisitors)
      }
      return 0
    },
    returningVisitorsPercent() {
      if (this.returningVisitors && this.totalVisitors) {
        return parseInt((this.returningVisitors * 100) / this.totalVisitors)
      }
      return 0
    },
    visitorsData() {
      return {
        visitorsRadialBar: {
          chartOptions: {
            labels: [this.$i18n.t('views.home.uniqueVisitors'), this.$i18n.t('views.home.returningVisitors')],
            plotOptions: {
              radialBar: {
                size: 165,
                offsetY: -5,
                hollow: {
                  size: '20%'
                },
                track: {
                  background: '#DEDDE0',
                  strokeWidth: '100%',
                  margin: 15
                },
                dataLabels: {
                  show: true,
                  name: {
                    fontSize: '18px'
                  },
                  value: {
                    fontSize: '16px',
                    offsetY: 11
                  },
                  total: {
                    show: true,
                    label: 'Total',
                    color: '#262629',
                    formatter: () => {
                      return this.totalVisitors
                    }
                  }
                }
              }
            },
            responsive: [
              {
                breakpoint: 576,
                options: {
                  plotOptions: {
                    radialBar: {
                      size: 150,
                      hollow: {
                        size: '20%'
                      },
                      track: {
                        background: '#DEDDE0',
                        strokeWidth: '100%',
                        margin: 15
                      }
                    }
                  }
                }
              }
            ],
            colors: ['rgba(241, 163, 66, 0.8)', 'rgba(151, 248, 121, 0.8)'],
            stroke: {
              lineCap: 'round'
            },
            chart: {
              height: 355
            }
          }
        },
        visitors: {
          analyticsData: [
            {
              orderType: this.$i18n.t('views.home.uniqueVisitors'),
              counts: this.uniqueVisitors,
              color: 'rgba(241, 163, 66, 0.8)'
            },
            {
              orderType: this.$i18n.t('views.home.returningVisitors'),
              counts: this.returningVisitors,
              color: 'rgba(151, 248, 121, 0.8)'
            }
          ],
          series: [this.uniqueVisitorsPercent, this.returningVisitorsPercent]
        }
      }
    },

    chatRequestPercent() {
      if (this.requestCount && this.chatRequestCount) {
        return parseFloat(((this.chatRequestCount * 100) / this.requestCount).toFixed(1))
      }
      return 0
    },
    audioRequestPercent() {
      if (this.requestCount && this.audioRequestCount) {
        return parseFloat(((this.audioRequestCount * 100) / this.requestCount).toFixed(1))
      }
      return 0
    },
    videoRequestPercent() {
      if (this.requestCount && this.videoRequestCount) {
        return parseFloat(((this.videoRequestCount * 100) / this.requestCount).toFixed(1))
      }
      return 0
    },

    vegaChatRequestPercent() {
      if (this.vegaRequestCount && this.vegaChatRequestCount) {
        return parseFloat(((this.vegaChatRequestCount * 100) / this.vegaRequestCount).toFixed(1))
      }
      return 0
    },
    vegaAudioRequestPercent() {
      if (this.vegaRequestCount && this.vegaAudioRequestCount) {
        return parseFloat(((this.vegaAudioRequestCount * 100) / this.vegaRequestCount).toFixed(1))
      }
      return 0
    },
    vegaVideoRequestPercent() {
      if (this.vegaRequestCount && this.vegaVideoRequestCount) {
        return parseFloat(((this.vegaVideoRequestCount * 100) / this.vegaRequestCount).toFixed(1))
      }
      return 0
    },

    requestsByType() {
      return {
        requestsByTypeDonut: {
          chartOptions: {
            labels: [this.$i18n.t('views.home.chatRequests'), this.$i18n.t('views.home.audioRequests'), this.$i18n.t('views.home.videoRequests')],
            dataLabels: {
              enabled: true
            },
            legend: { show: false },
            chart: {
              offsetY: 30,
              type: 'donut',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontFamily: 'Lato',
                      offsetY: -10
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      offsetY: 16,
                      formatter: (val) => {
                        return val
                      }
                    },
                    total: {
                      show: true,
                      label: 'Total',
                      color: '#262629',
                      formatter: () => {
                        return this.requestCount
                      }
                    }
                  }
                }
              }
            },
            stroke: { width: 0 },
            colors: ['#97F879', '#1EF8F0', '#FAD902']
          }
        },
        requests: {
          analyticsData: [
            {
              type: this.$i18n.t('views.home.chatRequests'),
              icon: 'MessageSquareIcon',
              color: '#97F879',
              percent: this.chatRequestPercent,
              total: this.chatRequestCount
            },
            {
              type: this.$i18n.t('views.home.audioRequests'),
              icon: 'MicIcon',
              color: '#1EF8F0',
              percent: this.audioRequestPercent,
              total: this.audioRequestCount
            },
            {
              type: this.$i18n.t('views.home.videoRequests'),
              icon: 'VideoIcon',
              color: '#FAD902',
              percent: this.videoRequestPercent,
              total: this.videoRequestCount
            }
          ],
          series: [this.chatRequestPercent, this.audioRequestPercent, this.videoRequestPercent]
        }
      }
    },

    vegaRequestsByType() {
      return {
        requestsByTypeDonut: {
          chartOptions: {
            labels: [this.$i18n.t('views.home.chatRequests'), this.$i18n.t('views.home.audioRequests'), this.$i18n.t('views.home.videoRequests')],
            dataLabels: {
              enabled: true
            },
            legend: { show: false },
            chart: {
              offsetY: 30,
              type: 'donut',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontFamily: 'Lato',
                      offsetY: -10
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      offsetY: 16,
                      formatter: (val) => {
                        return val
                      }
                    },
                    total: {
                      show: true,
                      label: 'Total',
                      color: '#262629',
                      formatter: () => {
                        return this.vegaRequestCount
                      }
                    }
                  }
                }
              }
            },
            stroke: { width: 0 },
            colors: ['#97F879', '#1EF8F0', '#FAD902']
          }
        },
        requests: {
          analyticsData: [
            {
              type: this.$i18n.t('views.home.chatRequests'),
              icon: 'MessageSquareIcon',
              color: '#97F879',
              percent: this.vegaChatRequestPercent,
              total: this.vegaChatRequestCount
            },
            {
              type: this.$i18n.t('views.home.audioRequests'),
              icon: 'MicIcon',
              color: '#1EF8F0',
              percent: this.vegaAudioRequestPercent,
              total: this.vegaAudioRequestCount
            },
            {
              type: this.$i18n.t('views.home.videoRequests'),
              icon: 'VideoIcon',
              color: '#FAD902',
              percent: this.vegaVideoRequestPercent,
              total: this.vegaVideoRequestCount
            }
          ],
          series: [this.vegaChatRequestPercent, this.vegaAudioRequestPercent, this.vegaVideoRequestPercent]
        }
      }
    },

    chatSessionPercent() {
      if (this.sessionsCount && this.chatSessionCount) {
        return parseFloat(((this.chatSessionCount * 100) / this.sessionsCount).toFixed(1))
      }
      return 0
    },
    audioSessionPercent() {
      if (this.sessionsCount && this.audioSessionCount) {
        return parseFloat(((this.audioSessionCount * 100) / this.sessionsCount).toFixed(1))
      }
      return 0
    },
    videoSessionPercent() {
      if (this.sessionsCount && this.videoSessionCount) {
        return parseFloat(((this.videoSessionCount * 100) / this.sessionsCount).toFixed(1))
      }
      return 0
    },

    vegaChatSessionPercent() {
      if (this.vegaSessionsCount && this.vegaChatSessionCount) {
        return parseFloat(((this.vegaChatSessionCount * 100) / this.vegaSessionsCount).toFixed(1))
      }
      return 0
    },
    vegaAudioSessionPercent() {
      if (this.vegaSessionsCount && this.vegaAudioSessionCount) {
        return parseFloat(((this.vegaAudioSessionCount * 100) / this.vegaSessionsCount).toFixed(1))
      }
      return 0
    },
    vegaVideoSessionPercent() {
      if (this.vegaSessionsCount && this.vegaVideoSessionCount) {
        return parseFloat(((this.vegaVideoSessionCount * 100) / this.vegaSessionsCount).toFixed(1))
      }
      return 0
    },

    sessionsByType() {
      return {
        sessionsByTypeDonut: {
          chartOptions: {
            labels: [this.$i18n.t('views.home.chatRequests'), this.$i18n.t('views.home.audioRequests'), this.$i18n.t('views.home.videoRequests')],
            dataLabels: {
              enabled: true
            },
            legend: { show: false },
            chart: {
              offsetY: 30,
              type: 'donut',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontFamily: 'Lato',
                      offsetY: -10
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      offsetY: 16,
                      formatter: (val) => {
                        return `${val}%`
                      }
                    },
                    total: {
                      show: true,
                      label: 'Total',
                      color: '#262629',
                      formatter: () => {
                        return this.sessionsCount
                      }
                    }
                  }
                }
              }
            },
            stroke: { width: 0 },
            colors: ['#97F879', '#1EF8F0', '#FAD902']
          }
        },
        sessions: {
          analyticsData: [
            {
              type: this.$i18n.t('views.home.chatRequests'),
              icon: 'MessageSquareIcon',
              color: '#97F879',
              percent: this.chatSessionPercent,
              total: this.chatSessionCount
            },
            {
              type: this.$i18n.t('views.home.audioRequests'),
              icon: 'MicIcon',
              color: '#1EF8F0',
              percent: this.audioSessionPercent,
              total: this.audioSessionCount
            },
            {
              type: this.$i18n.t('views.home.videoRequests'),
              icon: 'VideoIcon',
              color: '#FAD902',
              percent: this.videoSessionPercent,
              total: this.videoSessionCount
            }
          ],
          series: [this.chatSessionPercent, this.audioSessionPercent, this.videoSessionPercent]
        }
      }
    },

    vegaSessionsByType() {
      return {
        sessionsByTypeDonut: {
          chartOptions: {
            labels: [this.$i18n.t('views.home.chatRequests'), this.$i18n.t('views.home.audioRequests'), this.$i18n.t('views.home.videoRequests')],
            dataLabels: {
              enabled: true
            },
            legend: { show: false },
            chart: {
              offsetY: 30,
              type: 'donut',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontFamily: 'Lato',
                      offsetY: -10
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      offsetY: 16,
                      formatter: (val) => {
                        return `${val}%`
                      }
                    },
                    total: {
                      show: true,
                      label: 'Total',
                      color: '#262629',
                      formatter: () => {
                        return this.vegaSessionsCount
                      }
                    }
                  }
                }
              }
            },
            stroke: { width: 0 },
            colors: ['#97F879', '#1EF8F0', '#FAD902']
          }
        },
        sessions: {
          analyticsData: [
            {
              type: this.$i18n.t('views.home.chatRequests'),
              icon: 'MessageSquareIcon',
              color: '#97F879',
              percent: this.vegaChatSessionPercent,
              total: this.vegaChatSessionCount
            },
            {
              type: this.$i18n.t('views.home.audioRequests'),
              icon: 'MicIcon',
              color: '#1EF8F0',
              percent: this.vegaAudioSessionPercent,
              total: this.vegaAudioSessionCount
            },
            {
              type: this.$i18n.t('views.home.videoRequests'),
              icon: 'VideoIcon',
              color: '#FAD902',
              percent: this.vegaVideoSessionPercent,
              total: this.vegaVideoSessionCount
            }
          ],
          series: [this.vegaChatSessionPercent, this.vegaAudioSessionPercent, this.vegaVideoSessionPercent]
        }
      }
    },

    /* CAMPAIGNS */

    campaignChatRequestPercent() {
      if (this.campaignRequestCount && this.campaignChatRequestCount) {
        return parseFloat(((this.campaignChatRequestCount * 100) / this.campaignRequestCount).toFixed(1))
      }
      return 0
    },
    campaignAudioRequestPercent() {
      if (this.campaignRequestCount && this.campaignAudioRequestCount) {
        return parseFloat(((this.campaignAudioRequestCount * 100) / this.campaignRequestCount).toFixed(1))
      }
      return 0
    },
    campaignVideoRequestPercent() {
      if (this.campaignRequestCount && this.campaignVideoRequestCount) {
        return parseFloat(((this.campaignVideoRequestCount * 100) / this.campaignRequestCount).toFixed(1))
      }
      return 0
    },

    campaignRequestsByType() {
      return {
        requestsByTypeDonut: {
          chartOptions: {
            labels: [this.$i18n.t('views.home.chatRequests'), this.$i18n.t('views.home.audioRequests'), this.$i18n.t('views.home.videoRequests')],
            dataLabels: {
              enabled: true
            },
            legend: { show: false },
            chart: {
              offsetY: 30,
              type: 'donut',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontFamily: 'Lato',
                      offsetY: -10
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      offsetY: 16,
                      formatter: (val) => {
                        return val
                      }
                    },
                    total: {
                      show: true,
                      label: 'Total',
                      color: '#262629',
                      formatter: () => {
                        return this.campaignRequestCount
                      }
                    }
                  }
                }
              }
            },
            stroke: { width: 0 },
            colors: ['#97F879', '#1EF8F0', '#FAD902']
          }
        },
        requests: {
          analyticsData: [
            {
              type: this.$i18n.t('views.home.chatRequests'),
              icon: 'MessageSquareIcon',
              color: '#97F879',
              percent: this.campaignChatRequestPercent,
              total: this.campaignChatRequestCount
            },
            {
              type: this.$i18n.t('views.home.audioRequests'),
              icon: 'MicIcon',
              color: '#1EF8F0',
              percent: this.campaignAudioRequestPercent,
              total: this.campaignAudioRequestCount
            },
            {
              type: this.$i18n.t('views.home.videoRequests'),
              icon: 'VideoIcon',
              color: '#FAD902',
              percent: this.campaignVideoRequestPercent,
              total: this.campaignVideoRequestCount
            }
          ],
          series: [this.campaignChatRequestPercent, this.campaignAudioRequestPercent, this.campaignVideoRequestPercent]
        }
      }
    },

    campaignChatSessionPercent() {
      if (this.campaignSessionsCount && this.campaignChatSessionCount) {
        return parseFloat(((this.campaignChatSessionCount * 100) / this.campaignSessionsCount).toFixed(1))
      }
      return 0
    },
    campaignAudioSessionPercent() {
      if (this.campaignSessionsCount && this.campaignAudioSessionCount) {
        return parseFloat(((this.campaignAudioSessionCount * 100) / this.campaignSessionsCount).toFixed(1))
      }
      return 0
    },
    campaignVideoSessionPercent() {
      if (this.campaignSessionsCount && this.campaignVideoSessionCount) {
        return parseFloat(((this.campaignVideoSessionCount * 100) / this.campaignSessionsCount).toFixed(1))
      }
      return 0
    },

    campaignSessionsByType() {
      return {
        sessionsByTypeDonut: {
          chartOptions: {
            labels: [this.$i18n.t('views.home.chatRequests'), this.$i18n.t('views.home.audioRequests'), this.$i18n.t('views.home.videoRequests')],
            dataLabels: {
              enabled: true
            },
            legend: { show: false },
            chart: {
              offsetY: 30,
              type: 'donut',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontFamily: 'Lato',
                      offsetY: -10
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      offsetY: 16,
                      formatter: (val) => {
                        return `${val}%`
                      }
                    },
                    total: {
                      show: true,
                      label: 'Total',
                      color: '#262629',
                      formatter: () => {
                        return this.campaignSessionsCount
                      }
                    }
                  }
                }
              }
            },
            stroke: { width: 0 },
            colors: ['#97F879', '#1EF8F0', '#FAD902']
          }
        },
        sessions: {
          analyticsData: [
            {
              type: this.$i18n.t('views.home.chatRequests'),
              icon: 'MessageSquareIcon',
              color: '#97F879',
              percent: this.campaignChatSessionPercent,
              total: this.campaignChatSessionCount
            },
            {
              type: this.$i18n.t('views.home.audioRequests'),
              icon: 'MicIcon',
              color: '#1EF8F0',
              percent: this.campaignAudioSessionPercent,
              total: this.campaignAudioSessionCount
            },
            {
              type: this.$i18n.t('views.home.videoRequests'),
              icon: 'VideoIcon',
              color: '#FAD902',
              percent: this.campaignVideoSessionPercent,
              total: this.campaignVideoSessionCount
            }
          ],
          series: [this.campaignChatSessionPercent, this.campaignAudioSessionPercent, this.campaignVideoSessionPercent]
        }
      }
    },

    selectedVegaPopupThreshhold() {
      const defaultVegaPopupThreshhold = ['onfire', 'hot']

      if (!this.company || !this.company.vegaPopupThreshhold) {
        return defaultVegaPopupThreshhold
      }

      const scores = ['onfire', 'hot', 'warm', 'mild', 'cold', 'frozen']
      const selectedValue = this.company.vegaPopupThreshhold
      const selectedValueIndex = scores.indexOf(selectedValue)

      return selectedValueIndex >= 0 ? scores.slice(0, selectedValueIndex + 1) : defaultVegaPopupThreshhold
    }
  }
}
