export default {
  chartOptions: {
    responsive: [
      {
        breakpoint: 1024,
        options: {}
      }
    ],
    chart: {
      height: 200,
      type: 'line',
      zoom: {
        enabled: false
      },
      dropShadow: {
        enabled: true,
        top: 5,
        left: 0,
        blur: 4,
        opacity: 0.1
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2.5,
      curve: 'smooth'
    },
    colors: ['#dddddd', '#D91656'],
    title: {
      text: '',
      align: 'left'
    },
    xaxis: {
      categories: [...Array(24).keys()]
    },
    yaxis: {
      show: true,
      lines: {
        show: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.5,
        opacityTo: 0.2,
        stops: [0, 80, 100]
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 5
      }
    },
    tooltip: {
      x: { show: false }
    }
  }
}
