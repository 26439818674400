<template>
  <div class="data-range">
    <date-range-picker
      ref="picker"
      :opens="dateRangePickerConfig.opens"
      :locale-data="localeData"
      :singleDatePicker="dateRangePickerConfig.singleDatePicker"
      :showDropdowns="dateRangePickerConfig.showDropdowns"
      :autoApply="dateRangePickerConfig.autoApply"
      v-model="dateRangeObject"
      :showWeekNumbers="dateRangePickerConfig.showWeekNumbers"
      :maxDate="dateRangePickerConfig.maxDate"
      :minDate="dateRangePickerConfig.minDate"
      @update="updateValues"
      :key="pickerKey"
    >
      <template v-slot:input="picker">
        <div class="date-range-input-value">{{ picker.startDate | date }} - {{ picker.endDate | date }}</div>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { mapGetters } from 'vuex'

export default {
  name: 'DateRangeFilter',
  components: {
    DateRangePicker
  },
  filters: {
    date(val) {
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    }
  },
  data: () => ({
    dateRangeObject: {
      startDate: null,
      endDate: null
    },
    dateRangePickerConfig: {
      opens: 'left',
      singleDatePicker: false,
      showDropdowns: true,
      autoApply: true,
      linkedCalendars: true,
      showWeekNumbers: false,
      maxDate: new Date(),
      minDate: null
    },
    pickerKey: Math.random().toString(36).substring(2, 15)
  }),
  props: {
    localeData: {
      type: Object,
      required: true
    }
  },
  created() {
    this.dateRangeObject = this.dateRange
  },
  computed: {
    ...mapGetters({
      dateRange: 'dashboard/dateRange',
    }),
    ranges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayNight = new Date()
      todayNight.setDate(today.getDate())
      todayNight.setHours(23, 59, 59, 999)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)
      const yesterdayNight = new Date()
      yesterdayNight.setDate(today.getDate() - 1)
      yesterdayNight.setHours(23, 59, 59, 999)

      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      thisMonthEnd.setHours(23, 59, 59, 999)

      return {
        Today: [today, todayNight],
        Yesterday: [yesterday, yesterdayNight],
        'Last Week': [lastWeek, today],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Last year': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)]
      }
    }
  },
  methods: {
    updateValues(value) {
      value.startDate.setHours(0, 0, 0, 0)
      value.endDate.setHours(23, 59, 59, 999)

      this.$emit('dateRangeChanged', value)
    }
  }
}
</script>

<style lang="scss">
.data-range {
  width: 300px;
  min-width: 300px;

  @media only screen and (max-width: 1440px) {
    width: 250px;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  .vue-daterange-picker {
    width: 100%;

    .calendars {
      display: flex;
    }
    .daterangepicker {
      top: 40px;
    }

    .reportrange-text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 5px;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: 100%;
      min-height: 40px;
      cursor: pointer;
      border: none;
      background: #e6ecf1;
      color: #000;

      &::before {
        position: absolute;
        color: #999;
        content: '';
        border-color: #000 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        right: 15px;
        top: 18px;
      }

      .date-range-input-value {
        font-family: 'Lato', Helvetica, Arial, sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.01rem;
        font-size: 1em;
      }
    }
  }
}
</style>
