export const generateQuery = (config) => {
  let query = ''

  for (const paramName in config) {
    if (typeof config[paramName] === 'boolean') {
      query += ` dt.${paramName}=${config[paramName]} AND`
    } else {
      query += ` dt.${paramName}="${config[paramName]}" AND`
    }
  }

  query = query.substring(0, query.lastIndexOf(' '))

  return query
}

export const generateDialogFilter = (dialogsList, useTableName) => {
  if (dialogsList && dialogsList.length === 0) {
    return ' AND dt.dialog_id IN ("") '
  }

  if (dialogsList.length === 1) {
    return useTableName ? ` AND dt.dialog_id="${dialogsList[0].dialogId}"` : ` AND dialog_id="${dialogsList[0].dialogId}"`
  } else {
    let query = useTableName ? ' AND dt.dialog_id IN (' : ' AND dialog_id IN ('

    dialogsList.forEach((dialog) => {
      query += ` "${dialog.dialogId}" ,`
    })

    query = query.substring(0, query.lastIndexOf(' '))
    query += ' ) '

    return query
  }
}

export const generateDialogCampaignFilter = (dialogsList, campaignList, useTableName) => {
  let query = ''
  let dialogQuery = ''
  let campaignQuery = ''

  if (dialogsList.length > 0) {
    if (dialogsList.length === 1) {
      dialogQuery += useTableName ? ` dt.dialog_id="${dialogsList[0].dialogId}"` : ` dialog_id="${dialogsList[0].dialogId}"`
    } else {
      dialogQuery = useTableName ? ' dt.dialog_id IN (' : ' dialog_id IN ('

      dialogsList.forEach((dialog) => {
        dialogQuery += ` "${dialog.dialogId}" ,`
      })

      dialogQuery = dialogQuery.substring(0, dialogQuery.lastIndexOf(' '))
      dialogQuery += ' ) '
    }
  }

  if (campaignList.length > 0) {
    if (campaignList.length === 1) {
      campaignQuery += useTableName ? `  dt.campaign_id="${campaignList[0].campaignId}"` : ` campaign_id="${campaignList[0].campaignId}"`
    } else {
      campaignQuery = useTableName ? '  dt.campaign_id IN (' : ' campaign_id IN ('

      campaignList.forEach((campaign) => {
        campaignQuery += ` "${campaign.campaignId}" ,`
      })

      campaignQuery = campaignQuery.substring(0, campaignQuery.lastIndexOf(' '))
      campaignQuery += ' ) '
    }
  }

  if (dialogQuery && campaignQuery) {
    query = ` AND ( ${dialogQuery} OR  ${campaignQuery} )`
  } else {
    if (dialogQuery) {
      query = ` AND ${dialogQuery}`
    }
    if (campaignQuery) {
      query = ` AND ${campaignQuery}`
    }
  }

  if (query.length === 0) {
    query += ' AND dt.dialog_id IN ("") AND dt.campaign_id IN ("") '
  }
  return query
}

export const generateCampaignFilter = (campaignList, useTableName) => {
  if (campaignList.length > 0) {
    if (campaignList.length === 1) {
      return useTableName ? ` AND dt.campaign_id="${campaignList[0].campaignId}"` : `AND campaign_id="${campaignList[0].campaignId}"`
    } else {
      let query = useTableName ? ' AND dt.campaign_id IN (' : ' AND campaign_id IN ('

      campaignList.forEach((campaign) => {
        query += ` "${campaign.campaignId}" ,`
      })

      query = query.substring(0, query.lastIndexOf(' '))
      query += ' ) '

      return query
    }
  }
  return " AND dt.campaign_id IN ('') "
}
