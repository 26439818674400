var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _vm.activeUserInfo.superadmin
      ? _c(
          "div",
          { staticClass: "filters-container" },
          [
            _c("feather-icon", {
              staticClass: "filters-container-icon-filter",
              attrs: { icon: "FilterIcon" },
            }),
            _c("accounts-filter", {
              on: { accountChanged: _vm.handleAccountChange },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.isCampaignFilter
      ? _c(
          "div",
          { staticClass: "filters-container" },
          [
            _c("feather-icon", {
              staticClass: "filters-container-icon-filter",
              attrs: { icon: "FilterIcon" },
            }),
            _c("campaign-filter", {
              attrs: {
                campaignList: _vm.campaignList,
                selectedCampaigns: _vm.selectedCampaigns,
              },
              on: { campaignChanged: _vm.handleCampaignChange },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "filters-container" },
      [
        _c("feather-icon", {
          staticClass: "filters-container-icon-filter",
          attrs: { icon: "FilterIcon" },
        }),
        _c("dialog-filter", {
          attrs: {
            dialogList: _vm.dialogList,
            selectedDialogs: _vm.selectedDialogs,
          },
          on: { dialogChanged: _vm.handleDialogChange },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "filters-container" },
      [
        _c("feather-icon", {
          staticClass: "filters-container-icon-date_range",
          attrs: { icon: "CalendarIcon" },
        }),
        _c("date-range-filter", {
          attrs: { localeData: _vm.localeData },
          on: { dateRangeChanged: _vm.handleDateRangeChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }