var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info relative" },
    [
      _vm.isLoaded
        ? _c("vx-card", [
            _c("div", { staticClass: "flex flex-row items-center" }, [
              _c(
                "div",
                { staticClass: "relative w-full" },
                [
                  _c("vs-list-header", {
                    staticClass: "card-title-font-size",
                    attrs: { title: _vm.$t(_vm.title), color: "primary" },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              [
                _c("static-chart", {
                  attrs: {
                    series: _vm.series,
                    xaxis: _vm.xaxisValues,
                    color: _vm.color,
                  },
                }),
              ],
              1
            ),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }