var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-filter" },
    [
      _c("multiselect", {
        class: _vm.isEmpty ? "account-filter--empty" : "",
        attrs: {
          options: _vm.accountList,
          multiple: false,
          searchable: true,
          "close-on-select": true,
          "clear-on-select": false,
          "preserve-search": true,
          placeholder: _vm.$t("vue.selectAccount"),
          selectLabel: _vm.$t("vue.pressEnterToSelect"),
          selectedLabel: _vm.$t("vue.selectedLabel"),
          deselectLabel: _vm.$t("vue.pressEnterToRemove"),
          label: "name",
          "track-by": "name",
          "preselect-first": false,
        },
        on: { input: _vm.updateSelectedOption },
        model: {
          value: _vm.selectedOption,
          callback: function ($$v) {
            _vm.selectedOption = $$v
          },
          expression: "selectedOption",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }