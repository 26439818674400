var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-filter" },
    [
      _c("multiselect", {
        class: _vm.isEmpty ? "dialog-filter--empty" : "",
        attrs: {
          options: _vm.dialogList,
          multiple: true,
          "close-on-select": false,
          "clear-on-select": false,
          "preserve-search": true,
          placeholder: _vm.$t("vue.selectDialog"),
          selectLabel: _vm.$t("vue.pressEnterToSelect"),
          selectedLabel: _vm.$t("vue.selectedLabel"),
          deselectLabel: _vm.$t("vue.pressEnterToRemove"),
          label: "name",
          "track-by": "name",
          "preselect-first": false,
        },
        on: { input: _vm.updateSelectedDialog },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (ref) {
              var values = ref.values
              var isOpen = ref.isOpen
              return [
                values.length && !isOpen
                  ? _c("span", { staticClass: "multiselect__single" }, [
                      _vm._v(
                        _vm._s(values.length) +
                          " " +
                          _vm._s(
                            values.length > 1
                              ? _vm.$t("vue.dialogsSelected")
                              : _vm.$t("vue.dialogSelected")
                          )
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedOptions,
          callback: function ($$v) {
            _vm.selectedOptions = $$v
          },
          expression: "selectedOptions",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }